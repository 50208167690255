import { useState } from "react";
import axios from "axios";
import ProgressBar from "../components/ProgressBar";
import { ReactComponent as ExternalLinkIcon } from "../assets/external-link.svg";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import MobileProgressBar from "../components/MobileProgressBar";
import { NavLink } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TermsPage() {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [promoCodeValid, setPromoCodeValid] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  function nextDisabled() {
    setPromoCodeValid(true);
    if (window.location.href.includes("nofee")) {
      if (termsAgreed && document.getElementById("promo").value !== "") {
        return false;
      }
      return true;
    } else {
      return !termsAgreed;
    }
  }

  async function toStripe() {
    if (window.location.href.includes("nofee")) {
      const response = await axios.post(
        "https://ninho-automation.vercel.app/stripe/create-checkout/nofee",
        {
          firstName: localStorage.getItem("firstName"),
          lastName: localStorage.getItem("lastName"),
          email: localStorage.getItem("email"),
          phoneNumber: localStorage.getItem("phoneNumber"),
          vat: localStorage.getItem("vat"),
          address: localStorage.getItem("address"),
          city: localStorage.getItem("city"),
          country: localStorage.getItem("country"),
          postCode: localStorage.getItem("postCode"),
          membership: localStorage.getItem("membership"),
          promoCode: document.getElementById("promo").value,
          partnerName: localStorage.getItem("partnerName"),
          partnerEmail: localStorage.getItem("partnerEmail"),
          partnerNumber: localStorage.getItem("partnerNumber"),
          isAnnual: localStorage.getItem("isAnnual"),
        }
      );

      if (response.data.stat === "OK") {
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("email");
        localStorage.removeItem("phoneNumber");
        localStorage.removeItem("vat");
        localStorage.removeItem("address");
        localStorage.removeItem("city");
        localStorage.removeItem("country");
        localStorage.removeItem("postCode");
        localStorage.removeItem("membership");
        localStorage.removeItem("partnerName");
        localStorage.removeItem("partnerEmail");
        localStorage.removeItem("partnerNumber");
        localStorage.removeItem("isAnnual");

        window.location.replace(response.data.url);
      } else {
        setPromoCodeValid(false);
        return;
      }
    } else {
      const response = await axios.post(
        "https://ninho-automation.vercel.app/stripe/create-checkout",
        {
          firstName: localStorage.getItem("firstName"),
          lastName: localStorage.getItem("lastName"),
          email: localStorage.getItem("email"),
          phoneNumber: localStorage.getItem("phoneNumber"),
          vat: localStorage.getItem("vat"),
          address: localStorage.getItem("address"),
          city: localStorage.getItem("city"),
          country: localStorage.getItem("country"),
          postCode: localStorage.getItem("postCode"),
          membership: localStorage.getItem("membership"),
          partnerName: localStorage.removeItem("partnerName"),
          partnerEmail: localStorage.removeItem("partnerEmail"),
          partnerNumber: localStorage.removeItem("partnerNumber"),
          isAnnual: localStorage.getItem("isAnnual"),
        }
      );
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("email");
      localStorage.removeItem("phoneNumber");
      localStorage.removeItem("vat");
      localStorage.removeItem("address");
      localStorage.removeItem("city");
      localStorage.removeItem("country");
      localStorage.removeItem("postCode");
      localStorage.removeItem("membership");
      localStorage.removeItem("partnerName");
      localStorage.removeItem("partnerEmail");
      localStorage.removeItem("partnerNumber");
      localStorage.removeItem("isAnnual");

      window.location.replace(response.data.url);
    }
  }

  return (
    <>
      <MobileProgressBar />
      <div
        style={{ width: "90vw", maxWidth: "800px", margin: "0 auto" }}
        className="flex flex-grow lg:flex-none"
      >
        <div className="hidden lg:w-1/4 lg:block ">
          <ProgressBar />
        </div>

        <div className="w-full lg:w-3/4 space-y-4">
          <h2 className="text-lg md:text-xl text-avocado-700 font-semibold">
            Terms and conditions
          </h2>
          <fieldset className="space-y-5">
            <div className="relative flex items-start bg-avocado-100 rounded-md p-4">
              <div className="flex items-center h-5">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded accent-avocado-700 cursor-pointer mt-1"
                  onChange={() => setTermsAgreed(!termsAgreed)}
                  onInput={() => setButtonDisabled(nextDisabled)}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="comments"
                  className="text-sm md:text-base lg:text-base text-avocado-700 font-medium cursor-pointer"
                >
                  By checking you agree to our terms and conditions.
                </label>
                <NavLink
                  id="comments-description"
                  className=" block text-xs md:text-sm text-avocado-600 font-normal hover:underline"
                  to="https://ninho.io/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View our terms <ExternalLinkIcon className="inline" />
                </NavLink>
              </div>
            </div>
          </fieldset>
          {window.location.href.includes("nofee") ? (
            <div className="sm:col-span-2">
              <label
                htmlFor="promo-code"
                className="block text-sm font-medium text-avocado-700"
              >
                Promo Code <span className="text-dark-red">*</span>
              </label>
              <div className="mt-1 relative">
                <input
                  type="text"
                  name="promo"
                  id="promo"
                  className="block w-full bg-avocado-100 focus:outline focus:outline-avocado-700 p-2 rounded-md text-avocado-700"
                  onInput={() => setButtonDisabled(nextDisabled)}
                />
                <div
                  className={classNames(
                    "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none",
                    promoCodeValid ? "hidden" : null
                  )}
                >
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-dark-red"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <p
                className={classNames(
                  "absolute mt-1 text-xs text-dark-red",
                  promoCodeValid ? "hidden" : null
                )}
                id="email-error"
              >
                Please enter a valid promo code
              </p>
            </div>
          ) : null}
        </div>
      </div>

      <div
        className="flex-none mx-auto mb-5 mt-5 lg:block lg:static"
        style={{ width: "90vw", maxWidth: "800px", marginBottom: "5.5rem" }}
      >
        <p className="hidden md:block lg:hidden text-avocado-600 font-normal text-base text-center mb-3">
          Next, you will be redirected to Stripe
        </p>
        <div className="flex flex-col md:flex-row gap-3 items-center justify-around lg:justify-end lg:mt-0">
          <p className="text-sm md:hidden lg:block text-avocado-600 font-normal lg:mr-3 text-center">
            Next, you will be redirected to Stripe
          </p>
          <button
            className="text-avocado-600 w-full lg:w-20 rounded-md border border-avocado-600 p-2"
            onClick={toInformation}
          >
            Back
          </button>
          <button
            className={classNames(
              !buttonDisabled ? "bg-avocado-600" : "bg-avocado-deactive",
              "text-white w-full rounded-md p-2 lg:w-20"
            )}
            onClick={toStripe}
            disabled={buttonDisabled ? true : false}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

function toInformation() {
  window.location.href.includes("nofee")
    ? (window.location.href = "/nofee/information")
    : (window.location.href = "/information");
}
