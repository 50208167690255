import { useState, Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { countries } from "../data/coutnries";
import { vatCountries } from "../data/vatCountries";
import ProgressBar from "../components/ProgressBar";
import MobileProgressBar from "../components/MobileProgressBar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InformationPage() {
  const membershipType = localStorage.getItem("membership");

  const [selectedCountry, setSelectedCountry] = useState(countries[1]);
  const [selectedNumber, setSelectedNumber] = useState(countries[1]);
  const [selectedVAT, setSelectedVAT] = useState(vatCountries[1]);
  const [allMandatoryFilled, setAllMandatoryFilled] = useState(false);
  const [isAnual, setAnual] = useState(
    membershipType === "Studio" || membershipType === "Corporate" ? false : true
  );

  const [entriesValid, setEntriesValid] = useState({
    email: true,
    vat: true,
    partnerEmail: true,
    incompatibleVat: false,
  });

  useEffect(() => {
    if (!membershipType) {
      window.location.href = "membership";
    }
  }, []);

  useEffect(() => {
    checkAllMandatoryFilled();
  }, [selectedVAT, selectedCountry]);

  function vatBoxChanged(e) {
    setSelectedVAT((prevState) => e);
  }

  function selectAnnual() {
    setAnual(true);
  }

  function selectMonthly() {
    setAnual(false);
  }

  function checkAllMandatoryFilled() {
    const allInputs = document.getElementsByTagName("input");
    let errorFlag = false;

    // Checking email format
    if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        document.getElementById("email").value
      ) ||
      document.getElementById("email").value === ""
    ) {
      setEntriesValid((prevState) => ({ ...prevState, email: true }));
    } else {
      setEntriesValid((prevState) => ({ ...prevState, email: false }));
      setAllMandatoryFilled(false);
      errorFlag = true;
    }

    // Checking VAT
    if (
      /^(|(AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(HR)[0-9]{11}|(CY)[0-9]{8}[A-Z]|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL)[0-9]{9}|ES[A-Z][0-9]{7}(:[0-9]|[A-Z])|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]{7}[A-Z]{1,2}|(IE)[0-9][A-Z][0-9]{5}[A-Z]|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/.test(
        selectedVAT.VATCode + document.getElementById("vat").value
      ) ||
      document.getElementById("vat").value === ""
    ) {
      setEntriesValid((prevState) => ({
        ...prevState,
        vat: true,
        incompatibleVat: false,
      }));
    } else {
      setEntriesValid((prevState) => ({ ...prevState, vat: false }));
      setAllMandatoryFilled(false);
      errorFlag = true;
    }

    // Checking if we have same vat origin and billing address
    if (
      document.getElementById("selected-country").innerText !== selectedVAT.name &&
      document.getElementById("vat").value !== ""
    ) {
      setEntriesValid((prevState) => ({
        ...prevState,
        incompatibleVat: true,
        vat: false,
      }));
      setAllMandatoryFilled(false);
      errorFlag = true;
    }

    // Checking Partner Email
    if (membershipType === "Couples") {
      if (
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
          document.getElementById("partner-email").value
        ) ||
        document.getElementById("partner-email").value === ""
      ) {
        setEntriesValid((prevState) => ({ ...prevState, partnerEmail: true }));
      } else {
        setEntriesValid((prevState) => ({ ...prevState, partnerEmail: false }));
        setAllMandatoryFilled(false);
        errorFlag = true;
      }
    }

    for (let input of allInputs) {
      if (input.value === "" && input.id !== "vat") {
        setAllMandatoryFilled(false);
        errorFlag = true;
      }
    }

    if (!errorFlag) {
      setAllMandatoryFilled(true);
    }
  }

  return (
    <>
      <MobileProgressBar />
      <div
        style={{ width: "90vw", maxWidth: "800px", margin: "0 auto" }}
        className="flex flex-grow lg:flex-none"
      >
        <div className="hidden lg:w-1/4 lg:block">
          <ProgressBar />
        </div>

        <div className="w-full lg:w-3/4 space-y-4">
          <h2 className="text-lg md:text-xl text-avocado-700 font-semibold mt-2 lg:mt-0">
            Tell us about yourself
          </h2>
          <form className="space-y-8 divide-y divide-gray-200">
            <div className="mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              {membershipType !== "Studio" && membershipType !== "Corporate" ? (
                <div
                  className={
                    membershipType === "Out of town" ? "sm:col-span-6" : "sm:col-span-3"
                  }
                >
                  <label className="block text-sm font-medium text-avocado-700 grid-cols-6">
                    How would you like to pay?
                  </label>
                  <div className="mt-1">
                    <div
                      className={classNames(
                        "flex justify-between items-center bg-avocado-100 focus:outline focus:outline-avocado-700 p-2 px-4 rounded-md text-avocado-700 cursor-pointer",
                        membershipType === "Out of town" ? null : "-mb-10",
                        isAnual
                          ? "outline outline-1 outline-avocado-700"
                          : "hover:outline hover:outline-1 hover:outline-avocado-200"
                      )}
                      onClick={selectAnnual}
                    >
                      <div className="flex items-center">
                        <input
                          name="notification-method"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 cursor-pointer"
                          checked={isAnual}
                        />
                        <label className="ml-3 text-sm font-medium text-avocado-700 text-lg my-1 w-100 flex justify-around cursor-pointer">
                          Annually
                        </label>
                      </div>
                      {membershipType !== "Out of town" ? (
                        <span
                          className="text-xs p-2 rounded-2xl font-bold border border-x-avocado-700"
                          style={{ float: "right", lineHeight: ".75rem" }}
                        >
                          Save 5%
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}

              {membershipType !== "Out of town" ? (
                <div
                  className={classNames(
                    membershipType === "Studio" || membershipType === "Corporate"
                      ? "sm:col-span-6"
                      : "sm:col-span-3"
                  )}
                >
                  <label
                    className={classNames(
                      "block text-sm font-medium -z-20",
                      membershipType === "Studio" || membershipType === "Corporate"
                        ? null
                        : "invisible"
                    )}
                  >
                    How would you like to pay?
                  </label>
                  <div className="mt-1">
                    <div
                      className={classNames(
                        "flex items-center bg-avocado-100 p-2 rounded-md text-avocado-700 cursor-pointer px-4",
                        isAnual
                          ? "hover:outline hover:outline-1 hover:outline-avocado-200"
                          : "outline outline-1 outline-avocado-700"
                      )}
                      onClick={selectMonthly}
                    >
                      <input
                        name="notification-method"
                        type="radio"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mb-0"
                        checked={!isAnual}
                      />
                      <label className="ml-3 block text-sm font-medium text-avocado-700 my-1 cursor-pointer">
                        Monthly
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-avocado-700"
                >
                  First name <span className="text-dark-red">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full bg-avocado-100 focus:outline focus:outline-avocado-700 p-2 rounded-md text-avocado-700"
                    onInput={checkAllMandatoryFilled}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-avocado-700"
                >
                  Last name <span className="text-dark-red">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full bg-avocado-100 focus:outline focus:outline-avocado-700 p-2 rounded-md text-avocado-700"
                    onInput={checkAllMandatoryFilled}
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-avocado-700"
                >
                  Email address <span className="text-dark-red">*</span>
                </label>
                <div className="mt-1 relative">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className={classNames(
                      "block w-full bg-avocado-100 p-2 rounded-md text-avocado-700 ",
                      entriesValid.email
                        ? "focus:outline focus:outline-avocado-700"
                        : "outline outline-1 outline-dark-red"
                    )}
                    onInput={checkAllMandatoryFilled}
                  />
                  <div
                    className={classNames(
                      "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none",
                      entriesValid.email ? "hidden" : null
                    )}
                  >
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-dark-red"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <p
                  className={classNames(
                    "absolute mt-1 text-xs text-dark-red",
                    entriesValid.email ? "hidden" : null
                  )}
                  id="email-error"
                >
                  Please enter a valid email address
                </p>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium text-avocado-700 "
                >
                  Phone number <span className="text-dark-red">*</span>
                </label>
                <div className="mt-1 relative rounded-md bg-avocado-100">
                  <div className="inset-y-0 left-0 flex items-center">
                    <Listbox value={selectedNumber} onChange={setSelectedNumber}>
                      {({ open }) => (
                        <>
                          <div className="relative">
                            <Listbox.Button className="relative bg-avocado-100 rounded-l-md pr-8 p-2 text-avocado-700 text-left cursor-pointer focus:outline-none">
                              <span className="flex items-center">
                                <span className="ml-3 block truncate">
                                  {selectedNumber.dialCode}
                                </span>
                              </span>
                              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 bg-white max-h-56 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm w-56 shadow-lg">
                                {countries.map((country) => (
                                  <>
                                    <Listbox.Option
                                      key={country.dialCode}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-black bg-indigo-600"
                                            : "text-gray-900",
                                          "cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-avocado-100"
                                        )
                                      }
                                      value={country}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <div className="flex items-center">
                                            <img
                                              src={`https://hatscripts.github.io/circle-flags/flags/${country.circularFlag}`}
                                              alt=""
                                              className="flex-shrink-0 h-6 w-6 rounded-full"
                                            />
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "ml-3 block truncate"
                                              )}
                                            >
                                              {country.name}
                                            </span>
                                            <span className="font-light text-gray">
                                              &nbsp;
                                              {country.dialCode}
                                            </span>
                                          </div>
                                          {selected ? (
                                            <span
                                              className={classNames(
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5 text-black"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                    {country.name === "United States" ? (
                                      <hr className="my-2 border-light-gray"></hr>
                                    ) : null}
                                  </>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                    <input
                      type="number"
                      name="phone-number"
                      id="phone-number"
                      className="rounded-tr-md rounded-br-md rounded-tl-none rounded-bl-none block w-full p-2 bg-avocado-100 focus:outline focus:outline-avocado-700 focus:z-20 text-avocado-700"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="flex justify-between">
                  <label
                    htmlFor="vat"
                    className="block text-sm font-medium text-avocado-700"
                  >
                    VAT No.
                  </label>
                  <div className="text-avocado-200">Optional</div>
                </div>
                <div className="relative rounded-md bg-avocado-100">
                  <div className="inset-y-0 left-0 flex items-center">
                    <Listbox value={selectedVAT} onChange={vatBoxChanged}>
                      {({ open }) => (
                        <>
                          <div className="relative mr-1">
                            <Listbox.Button className="relative bg-avocado-100 rounded-l-md pr-8 p-2 text-avocado-700 text-left cursor-pointer focus:outline-none">
                              <span className="flex items-center">
                                <span className="ml-3 block truncate" id="vat-selector">
                                  {selectedVAT.VATCode}
                                </span>
                              </span>
                              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 bg-white max-h-56 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm w-56 shadow-lg">
                                {vatCountries.map((country) => (
                                  <>
                                    <Listbox.Option
                                      key={country.VATCode}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-black bg-indigo-600"
                                            : "text-gray-900",
                                          "cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-avocado-100"
                                        )
                                      }
                                      value={country}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <div className="flex items-center">
                                            <img
                                              src={`https://hatscripts.github.io/circle-flags/flags/${country.circularFlag}`}
                                              alt=""
                                              className="flex-shrink-0 h-6 w-6 rounded-full"
                                            />
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "ml-3 block truncate"
                                              )}
                                            >
                                              {country.name}
                                            </span>
                                            <span className="font-light text-gray">
                                              &nbsp;&nbsp;
                                              {country.VATCode}
                                            </span>
                                          </div>
                                          {selected ? (
                                            <span
                                              className={classNames(
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5 text-black"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                    {country.name === "UK" ? (
                                      <hr className="my-2 border-light-gray bg-white"></hr>
                                    ) : null}
                                  </>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                    <div className="w-full">
                      <input
                        type="text"
                        name="vat"
                        id="vat"
                        className={classNames(
                          "block w-full p-2 bg-avocado-100 rounded-r-md text-avocado-700",
                          entriesValid.vat
                            ? "focus:outline focus:outline-avocado-700 focus:z-20"
                            : "outline outline-1 outline-dark-red"
                        )}
                        onInput={checkAllMandatoryFilled}
                      />
                      <div
                        className={classNames(
                          "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none",
                          entriesValid.vat ? "hidden" : null
                        )}
                      >
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-dark-red"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {entriesValid.incompatibleVat ? (
                  <p
                    className={classNames(
                      "absolute mt-1 text-xs text-dark-red",
                      entriesValid.vat ? "hidden" : null
                    )}
                    id="email-error"
                  >
                    Your VAT No. & billing country must be the same
                  </p>
                ) : (
                  <p
                    className={classNames(
                      "absolute mt-1 text-xs text-dark-red",
                      entriesValid.vat ? "hidden" : null
                    )}
                    id="email-error"
                  >
                    The VAT no. entered is not the correct format
                  </p>
                )}
              </div>
              <hr className="hidden md:inline sm:col-span-1 border-white my-3"></hr>
              <hr className="sm:col-span-4 border border-avocado-100 my-3"></hr>

              <div className="sm:col-span-3">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-avocado-700"
                >
                  Address <span className="text-dark-red">*</span>
                </label>
                <div className="mt-1">
                  <input
                    id="address"
                    name="address"
                    type="text"
                    autoComplete="street-address"
                    className="block w-full bg-avocado-100 focus:outline focus:outline-avocado-700 p-2 rounded-md text-avocado-700"
                    onInput={checkAllMandatoryFilled}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-avocado-700"
                >
                  City <span className="text-dark-red">*</span>
                </label>
                <div className="mt-1">
                  <input
                    id="city"
                    name="city"
                    type="text"
                    autoComplete="address-level2"
                    className={classNames(
                      "block w-full bg-avocado-100 focus:outline focus:outline-avocado-700 p-2 rounded-md text-avocado-700",
                      entriesValid.vat ? "null" : ""
                    )}
                    onInput={checkAllMandatoryFilled}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="Country"
                  className="block text-sm font-medium text-avocado-700"
                >
                  Country <span className="text-dark-red">*</span>
                </label>
                <div className="mt-1 relative">
                  <Listbox value={selectedCountry} onChange={setSelectedCountry}>
                    {({ open }) => (
                      <>
                        <div className="mt-1 relative">
                          <Listbox.Button className="relative w-full bg-avocado-100 rounded-md pl-2 pr-10 py-2 text-avocado-700 text-left cursor-pointer focus:outline-none">
                            <span className="flex items-center">
                              <img
                                src={`https://hatscripts.github.io/circle-flags/flags/${selectedCountry.circularFlag}`}
                                alt=""
                                className="flex-shrink-0 h-6 w-6 rounded-full"
                              />
                              <span className="ml-3 block truncate" id="selected-country">
                                {selectedCountry.name}
                              </span>
                            </span>
                            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-30 mt-1 w-full bg-white bg-whitemax-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm h-56 shadow-lg">
                              {countries.map((country) => (
                                <>
                                  <Listbox.Option
                                    key={country.dialCode}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-black bg-indigo-600"
                                          : "text-gray-900",
                                        "cursor-pointer select-none relative py-2 pl-3 pr-9 bg-white hover:bg-avocado-100"
                                      )
                                    }
                                    value={country}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <div className="flex items-center">
                                          <img
                                            src={`https://hatscripts.github.io/circle-flags/flags/${country.circularFlag}`}
                                            alt=""
                                            className="flex-shrink-0 h-6 w-6 rounded-full"
                                          />
                                          <span
                                            className={classNames(
                                              selected ? "font-semibold" : "font-normal",
                                              "ml-3 block truncate"
                                            )}
                                          >
                                            {country.name}
                                          </span>
                                        </div>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5 text-black"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                  {country.name === "United States" ? (
                                    <hr className="my-2 border-light-gray bg-white z-50"></hr>
                                  ) : null}
                                </>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium text-avocado-700"
                >
                  Post code <span className="text-dark-red">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autoComplete="postal-code"
                    className="block w-full bg-avocado-100 focus:outline focus:outline-avocado-700 p-2 rounded-md text-base text-avocado-700"
                    onInput={checkAllMandatoryFilled}
                  />
                </div>
              </div>
            </div>
          </form>

          {membershipType === "Couples" ? (
            <>
              {" "}
              <hr className="sm:col-span-1 border-white"></hr>
              <hr className="sm:col-span-4 border border-avocado-100"></hr>
              <h2 className="text-lg md:text-xl text-avocado-700 font-semibold mt-2 lg:mt-0">
                About your partner
              </h2>
              <form className="space-y-8 divide-y divide-gray-200">
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="couple-name"
                      className="block text-sm font-medium text-avocado-700"
                    >
                      First name <span className="text-dark-red">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="couple-name"
                        id="couple-name"
                        className="block w-full bg-avocado-100 focus:outline-none rounded-md p-2"
                        onInput={checkAllMandatoryFilled}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="couple-last-name"
                      className="block text-sm font-medium text-avocado-700"
                    >
                      Last name <span className="text-dark-red">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="couple-last-name"
                        id="couple-last-name"
                        className="block w-full bg-avocado-100 focus:outline-none rounded-md p-2"
                        onInput={checkAllMandatoryFilled}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="partner-email"
                      className="block text-sm font-medium text-avocado-700"
                    >
                      Email address <span className="text-dark-red">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        id="partner-email"
                        name="partner-email"
                        type="email"
                        className="block w-full bg-avocado-100 focus:outline-none rounded-md p-2"
                        onInput={checkAllMandatoryFilled}
                      />
                      <div
                        className={classNames(
                          "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none",
                          entriesValid.partnerEmail ? "hidden" : null
                        )}
                      >
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-dark-red"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <p
                      className={classNames(
                        "absolute mt-1 text-xs text-dark-red",
                        entriesValid.partnerEmail ? "hidden" : null
                      )}
                      id="email-error"
                    >
                      Please enter a valid email address
                    </p>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="couple-number"
                      className="block text-sm font-medium text-avocado-700"
                    >
                      Phone number <span className="text-dark-red">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="number"
                        name="couple-number"
                        id="couple-number"
                        className="block w-full bg-avocado-100 focus:outline-none rounded-md p-2"
                        onInput={checkAllMandatoryFilled}
                      />
                    </div>
                  </div>
                </div>
              </form>{" "}
            </>
          ) : null}
        </div>
      </div>
      <div
        className={"flex-none mx-auto mb-5 mt-5 lg:block lg:static"}
        style={{ width: "90vw", maxWidth: "800px" }}
      >
        <div className="flex flex-col md:flex-row gap-3 items-center justify-around lg:justify-end">
          <button
            className="text-avocado-600 w-full lg:w-20 rounded-md border border-avocado-600 p-2"
            onClick={toMembership}
          >
            Back
          </button>
          <button
            className={classNames(
              allMandatoryFilled ? "bg-avocado-600" : "bg-avocado-deactive",
              "text-white  w-full lg:w-20 rounded-md p-2"
            )}
            onClick={() => toTerms(isAnual)}
            disabled={allMandatoryFilled ? false : true}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

function toTerms(isAnnual) {
  localStorage.setItem("firstName", document.getElementById("first-name").value);
  localStorage.setItem("lastName", document.getElementById("last-name").value);
  localStorage.setItem("email", document.getElementById("email").value);
  localStorage.setItem("phoneNumber", document.getElementById("phone-number").value);
  document.getElementById("vat").value === ""
    ? localStorage.setItem("vat", "")
    : localStorage.setItem(
        "vat",
        document.getElementById("vat-selector").innerText +
          document.getElementById("vat").value
      );
  localStorage.setItem("address", document.getElementById("address").value);
  localStorage.setItem("city", document.getElementById("city").value);
  localStorage.setItem("country", document.getElementById("selected-country").innerText);
  localStorage.setItem("postCode", document.getElementById("postal-code").value);
  localStorage.setItem("isAnnual", isAnnual);

  if (localStorage.getItem("membership") === "Couples") {
    localStorage.setItem(
      "partnerName",
      document.getElementById("couple-name").value +
        " " +
        document.getElementById("couple-last-name").value
    );
    localStorage.setItem("partnerEmail", document.getElementById("partner-email").value);
    localStorage.setItem("partnerNumber", document.getElementById("couple-number").value);
  }

  window.location.href.includes("nofee")
    ? (window.location.href = "/nofee/terms")
    : (window.location.href = "/terms");
}

function toMembership() {
  window.location.href.includes("nofee")
    ? (window.location.href = "/nofee")
    : (window.location.href = "/");
}
