export const countries = [
  { name: "Germany", dialCode: "+49", circularFlag: "de.svg" },
  { name: "Portugal", dialCode: "+351", circularFlag: "pt.svg" },
  { name: "UK", dialCode: "+44", circularFlag: "gb.svg" },
  { name: "United States", dialCode: "+1", circularFlag: "us.svg" },
  { name: "Afghanistan", dialCode: "+93", circularFlag: "af.svg" },
  { name: "Albania", dialCode: "+355", circularFlag: "al.svg" },
  { name: "Algeria", dialCode: "+213", circularFlag: "dz.svg" },
  { name: "American Samoa", dialCode: "+1684", circularFlag: "as.svg" },
  { name: "Andorra", dialCode: "+376", circularFlag: "ad.svg" },
  { name: "Angola", dialCode: "+244", circularFlag: "ao.svg" },
  { name: "Anguilla", dialCode: "+1264", circularFlag: "ai.svg" },
  { name: "Antigua and Barbuda", dialCode: "+1268", circularFlag: "ag.svg" },
  { name: "Argentina", dialCode: "+54", circularFlag: "ar.svg" },
  { name: "Armenia", dialCode: "+374", circularFlag: "am.svg" },
  { name: "Aruba", dialCode: "+297", circularFlag: "aw.svg" },
  { name: "Australia", dialCode: "+61", circularFlag: "au.svg" },
  { name: "Austria", dialCode: "+43", circularFlag: "at.svg" },
  { name: "Azerbaijan", dialCode: "+994", circularFlag: "az.svg" },
  { name: "Bahamas", dialCode: "+1242", circularFlag: "bs.svg" },
  { name: "Bahrain", dialCode: "+973", circularFlag: "bh.svg" },
  { name: "Bangladesh", dialCode: "+880", circularFlag: "bd.svg" },
  { name: "Barbados", dialCode: "+1246", circularFlag: "bb.svg" },
  { name: "Belarus", dialCode: "+375", circularFlag: "by.svg" },
  { name: "Belgium", dialCode: "+32", circularFlag: "be.svg" },
  { name: "Belize", dialCode: "+501", circularFlag: "bz.svg" },
  { name: "Benin", dialCode: "+229", circularFlag: "bj.svg" },
  { name: "Bermuda", dialCode: "+1441", circularFlag: "bm.svg" },
  { name: "Bhutan", dialCode: "+975", circularFlag: "bt.svg" },
  { name: "Bolivia", dialCode: "+591", circularFlag: "bo.svg" },
  { name: "Bosnia-Herzegovina", dialCode: "+387", circularFlag: "ba.svg" },
  { name: "Botswana", dialCode: "+267", circularFlag: "bw.svg" },
  { name: "Brazil", dialCode: "+55", circularFlag: "br.svg" },
  {
    name: "British Indian Ocean Territory",
    dialCode: "+246",
    circularFlag: "io.svg",
  },
  { name: "Brunei", dialCode: "+673", circularFlag: "bn.svg" },
  { name: "Bulgaria", dialCode: "+359", circularFlag: "bg.svg" },
  { name: "Burkina Faso", dialCode: "+226", circularFlag: "bf.svg" },
  { name: "Burundi", dialCode: "+257", circularFlag: "bi.svg" },
  { name: "Cambodia", dialCode: "+855", circularFlag: "kh.svg" },
  { name: "Cameroon", dialCode: "+237", circularFlag: "cm.svg" },
  { name: "Canada", dialCode: "+1", circularFlag: "ca.svg" },
  { name: "Cape Verde", dialCode: "+238", circularFlag: "cv.svg" },
  { name: "Cayman Islands", dialCode: "+345", circularFlag: "ky.svg" },
  { name: "Central African Republic", dialCode: "+236", circularFlag: "cf.svg" },
  { name: "Chad", dialCode: "+235", circularFlag: "td.svg" },
  { name: "Chile", dialCode: "+56", circularFlag: "cl.svg" },
  { name: "China", dialCode: "+86", circularFlag: "cn.svg" },
  { name: "Christmas Island", dialCode: "+61", circularFlag: "cx.svg" },
  { name: "Cocos (Keeling) Islands", dialCode: "+61", circularFlag: "cc.svg" },
  { name: "Colombia", dialCode: "+57", circularFlag: "co.svg" },
  { name: "Comoros", dialCode: "+269", circularFlag: "km.svg" },
  { name: "Congo", dialCode: "+242", circularFlag: "cg.svg" },
  { name: "Congo, Democratic Republic", dialCode: "+243", circularFlag: "cd.svg" },
  { name: "Cook Islands", dialCode: "+682", circularFlag: "ck.svg" },
  { name: "Costa Rica", dialCode: "+506", circularFlag: "cr.svg" },
  { name: "Côte d’Ivoire", dialCode: "+225", circularFlag: "ci.svg" },
  { name: "Croatia", dialCode: "+385", circularFlag: "hr.svg" },
  { name: "Cuba", dialCode: "+53", circularFlag: "cu.svg" },
  { name: "Cyprus", dialCode: "+357", circularFlag: "cy.svg" },
  { name: "Czech Republic", dialCode: "+420", circularFlag: "cz.svg" },
  { name: "Denmark", dialCode: "+45", circularFlag: "dk.svg" },
  { name: "Djibouti", dialCode: "+253", circularFlag: "dj.svg" },
  { name: "Dominica", dialCode: "+1767", circularFlag: "dm.svg" },
  { name: "Dominican Republic", dialCode: "+1849", circularFlag: "do.svg" },
  { name: "Ecuador", dialCode: "+593", circularFlag: "ec.svg" },
  { name: "Egypt", dialCode: "+20", circularFlag: "eg.svg" },
  { name: "El Salvador", dialCode: "+503", circularFlag: "sv.svg" },
  { name: "Equatorial Guinea", dialCode: "+240", circularFlag: "gq.svg" },
  { name: "Eritrea", dialCode: "+291", circularFlag: "er.svg" },
  { name: "Estonia", dialCode: "+372", circularFlag: "ee.svg" },
  { name: "Ethiopia", dialCode: "+251", circularFlag: "et.svg" },
  { name: "Faeroe Islands (Føroyar)", dialCode: "+298", circularFlag: "fo.svg" },
  { name: "Fiji", dialCode: "+679", circularFlag: "fj.svg" },
  { name: "Finland", dialCode: "+358", circularFlag: "fi.svg" },
  { name: "France", dialCode: "+33", circularFlag: "fr.svg" },
  { name: "French Guiana", dialCode: "+594", circularFlag: "gf.svg" },
  { name: "French Polynesia", dialCode: "+689", circularFlag: "pf.svg" },
  { name: "Gabon", dialCode: "+241", circularFlag: "ga.svg" },
  { name: "Gambia", dialCode: "+220", circularFlag: "gm.svg" },
  { name: "Georgia", dialCode: "+995", circularFlag: "ge.svg" },
  { name: "Ghana", dialCode: "+233", circularFlag: "gh.svg" },
  { name: "Gibraltar", dialCode: "+350", circularFlag: "gi.svg" },
  { name: "Greece", dialCode: "+30", circularFlag: "gr.svg" },
  { name: "Greenland", dialCode: "+299", circularFlag: "gl.svg" },
  { name: "Grenada", dialCode: "+1473", circularFlag: "gd.svg" },
  { name: "Guadeloupe", dialCode: "+590", circularFlag: "gp.svg" },
  { name: "Guam", dialCode: "+1671", circularFlag: "gu.svg" },
  { name: "Guatemala", dialCode: "+502", circularFlag: "gt.svg" },
  { name: "Guernsey", dialCode: "+44", circularFlag: "gg.svg" },
  { name: "Guinea-Bissau", dialCode: "+245", circularFlag: "gw.svg" },
  { name: "Guinea", dialCode: "+224", circularFlag: "gn.svg" },
  { name: "Guyana", dialCode: "+595", circularFlag: "gy.svg" },
  { name: "Haiti", dialCode: "+509", circularFlag: "ht.svg" },
  { name: "Honduras", dialCode: "+504", circularFlag: "hn.svg" },
  { name: "Hong Kong", dialCode: "+852", circularFlag: "hk.svg" },
  { name: "Hungary", dialCode: "+36", circularFlag: "hu.svg" },
  { name: "Iceland", dialCode: "+354", circularFlag: "is.svg" },
  { name: "India", dialCode: "+91", circularFlag: "in.svg" },
  { name: "Indonesia", dialCode: "+62", circularFlag: "id.svg" },
  { name: "Iran", dialCode: "+98", circularFlag: "ir.svg" },
  { name: "Iraq", dialCode: "+964", circularFlag: "iq.svg" },
  { name: "Ireland", dialCode: "+353", circularFlag: "ie.svg" },
  { name: "Isle of Man", dialCode: "+44", circularFlag: "im.svg" },
  { name: "Israel", dialCode: "+972", circularFlag: "il.svg" },
  { name: "Italy", dialCode: "+39", circularFlag: "it.svg" },
  { name: "Jamaica", dialCode: "+1876", circularFlag: "jm.svg" },
  { name: "Japan", dialCode: "+81", circularFlag: "jp.svg" },
  { name: "Jersey", dialCode: "+44", circularFlag: "je.svg" },
  { name: "Jordan", dialCode: "+962", circularFlag: "jo.svg" },
  { name: "Kazakhstan", dialCode: "+77", circularFlag: "kz.svg" },
  { name: "Kenya", dialCode: "+254", circularFlag: "ke.svg" },
  { name: "Kiribati", dialCode: "+686", circularFlag: "ki.svg" },
  { name: "Korea, North", dialCode: "+850", circularFlag: "kp.svg" },
  { name: "Korea, South", dialCode: "+82", circularFlag: "kr.svg" },
  { name: "Kuwait", dialCode: "+965", circularFlag: "kw.svg" },
  { name: "Kyrgyzstan", dialCode: "+996", circularFlag: "kg.svg" },
  { name: "Laos", dialCode: "+856", circularFlag: "la.svg" },
  { name: "Latvia", dialCode: "+371", circularFlag: "lv.svg" },
  { name: "Lebanon", dialCode: "+961", circularFlag: "lb.svg" },
  { name: "Lesotho", dialCode: "+266", circularFlag: "ls.svg" },
  { name: "Liberia", dialCode: "+231", circularFlag: "lr.svg" },
  { name: "Libya", dialCode: "+218", circularFlag: "ly.svg" },
  { name: "Liechtenstein", dialCode: "+423", circularFlag: "li.svg" },
  { name: "Lithuania", dialCode: "+370", circularFlag: "lt.svg" },
  { name: "Luxembourg", dialCode: "+352", circularFlag: "lu.svg" },
  { name: "Macau", dialCode: "+853", circularFlag: "mo.svg" },
  {
    name: "Macedonia (Former Yug. Rep.)",
    dialCode: "+389",
    circularFlag: "mk.svg",
  },
  { name: "Madagascar", dialCode: "+261", circularFlag: "mg.svg" },
  { name: "Malawi", dialCode: "+265", circularFlag: "mw.svg" },
  { name: "Malaysia", dialCode: "+60", circularFlag: "my.svg" },
  { name: "Maldives", dialCode: "+960", circularFlag: "mv.svg" },
  { name: "Mali", dialCode: "+223", circularFlag: "ml.svg" },
  { name: "Malta", dialCode: "+356", circularFlag: "mt.svg" },
  { name: "Marshall Islands", dialCode: "+692", circularFlag: "mh.svg" },
  { name: "Martinique", dialCode: "+596", circularFlag: "mq.svg" },
  { name: "Mauritania", dialCode: "+222", circularFlag: "mr.svg" },
  { name: "Mauritius", dialCode: "+230", circularFlag: "mu.svg" },
  { name: "Mayotte", dialCode: "+262", circularFlag: "yt.svg" },
  { name: "Micronesia", dialCode: "+691", circularFlag: "fm.svg" },
  { name: "Midway Islands", dialCode: "+853", circularFlag: "mo.svg" },
  { name: "Mexico", dialCode: "+52", circularFlag: "mx.svg" },
  { name: "Moldova", dialCode: "+373", circularFlag: "md.svg" },
  { name: "Monaco", dialCode: "+377", circularFlag: "mc.svg" },
  { name: "Mongolia", dialCode: "+976", circularFlag: "mn.svg" },
  { name: "Montenegro", dialCode: "+382", circularFlag: "me.svg" },
  { name: "Montserrat", dialCode: "+1664", circularFlag: "ms.svg" },
  { name: "Morocco", dialCode: "+212", circularFlag: "ma.svg" },
  { name: "Mozambique", dialCode: "+258", circularFlag: "mz.svg" },
  { name: "Myanmar", dialCode: "+95", circularFlag: "mm.svg" },
  { name: "Nauru", dialCode: "+674", circularFlag: "nr.svg" },
  { name: "Namibia", dialCode: "+264", circularFlag: "na.svg" },
  { name: "Nepal", dialCode: "+977", circularFlag: "np.svg" },
  { name: "Netherlands Antilles", dialCode: "+599", circularFlag: "an.svg" },
  { name: "Netherlands", dialCode: "+31", circularFlag: "nl.svg" },
  { name: "New Caledonia", dialCode: "+687", circularFlag: "nc.svg" },
  { name: "New Zealand", dialCode: "+64", circularFlag: "nz.svg" },
  { name: "Nicaragua", dialCode: "+505", circularFlag: "ni.svg" },
  { name: "Niger", dialCode: "+227", circularFlag: "ne.svg" },
  { name: "Nigeria", dialCode: "+234", circularFlag: "ng.svg" },
  { name: "Niue", dialCode: "+683", circularFlag: "nu.svg" },
  { name: "Norfolk Island", dialCode: "+672", circularFlag: "nf.svg" },
  { name: "Northern Mariana Islands", dialCode: "+1670", circularFlag: "mp.svg" },
  { name: "Norway", dialCode: "+47", circularFlag: "no.svg" },
  { name: "Oman", dialCode: "+968", circularFlag: "om.svg" },
  { name: "Pakistan", dialCode: "+92", circularFlag: "pk.svg" },
  { name: "Palau", dialCode: "+680", circularFlag: "pw.svg" },
  { name: "Panama", dialCode: "+507", circularFlag: "pa.svg" },
  { name: "Papua New Guinea", dialCode: "+675", circularFlag: "pg.svg" },
  { name: "Paraguay", dialCode: "+595", circularFlag: "py.svg" },
  { name: "Peru", dialCode: "+51", circularFlag: "pe.svg" },
  { name: "Philippines", dialCode: "+63", circularFlag: "ph.svg" },
  { name: "Poland", dialCode: "+48", circularFlag: "pl.svg" },

  { name: "Puerto Rico", dialCode: "+1939", circularFlag: "pr.svg" },
  { name: "Qatar", dialCode: "+974", circularFlag: "qa.svg" },
  { name: "Reunion", dialCode: "+262", circularFlag: "re.svg" },
  { name: "Romania", dialCode: "+40", circularFlag: "ro.svg" },
  { name: "Russia", dialCode: "+7", circularFlag: "ru.svg" },
  { name: "Rwanda", dialCode: "+250", circularFlag: "rw.svg" },
  { name: "San Marino", dialCode: "+378", circularFlag: "sm.svg" },
  { name: "Saudi Arabia", dialCode: "+966", circularFlag: "sa.svg" },
  { name: "Serbia", dialCode: "+381", circularFlag: "rs.svg" },
  { name: "Seychelles", dialCode: "+248", circularFlag: "sc.svg" },
  { name: "Sierra Leone", dialCode: "+232", circularFlag: "sl.svg" },
  { name: "Singapore", dialCode: "+65", circularFlag: "sg.svg" },
  { name: "Slovakia", dialCode: "+421", circularFlag: "sk.svg" },
  { name: "Slovenia", dialCode: "+386", circularFlag: "si.svg" },
  { name: "Solomon Islands", dialCode: "+677", circularFlag: "sb.svg" },
  { name: "Somalia", dialCode: "+252", circularFlag: "so.svg" },
  { name: "South Africa", dialCode: "+27", circularFlag: "za.svg" },
  { name: "Spain", dialCode: "+34", circularFlag: "es.svg" },
  { name: "Sri Lanka", dialCode: "+94", circularFlag: "lk.svg" },
  { name: "St. Helena", dialCode: "+290", circularFlag: "sh.svg" },
  { name: "St. Kitts and Nevis", dialCode: "+1869", circularFlag: "kn.svg" },
  { name: "St. Lucia", dialCode: "+1758", circularFlag: "lc.svg" },
  {
    name: "St. Vincent and the Grenadines",
    dialCode: "+1784",
    circularFlag: "vc.svg",
  },
  { name: "Sudan", dialCode: "+249", circularFlag: "sd.svg" },
  { name: "Suriname", dialCode: "+597", circularFlag: "sr.svg" },
  { name: "Swaziland", dialCode: "+268", circularFlag: "sz.svg" },
  { name: "Sweden", dialCode: "+46", circularFlag: "se.svg" },
  { name: "Switzerland", dialCode: "+41", circularFlag: "ch.svg" },
  { name: "Syria", dialCode: "+963", circularFlag: "sy.svg" },
  { name: "Taiwan", dialCode: "+886", circularFlag: "tw.svg" },
  { name: "Tajikistan", dialCode: "+992", circularFlag: "tj.svg" },
  { name: "Tanzania", dialCode: "+255", circularFlag: "tz.svg" },
  { name: "Thailand", dialCode: "+66", circularFlag: "th.svg" },
  { name: "Timor-Leste", dialCode: "+670", circularFlag: "tl.svg" },
  { name: "Togo", dialCode: "+228", circularFlag: "tg.svg" },
  { name: "Trinidad and Tobago", dialCode: "+1868", circularFlag: "tt.svg" },
  { name: "Tunisia", dialCode: "+216", circularFlag: "tn.svg" },
  { name: "Turkey", dialCode: "+90", circularFlag: "tr.svg" },
  { name: "Turkmenistan", dialCode: "+993", circularFlag: "tm.svg" },
  { name: "Turks and Caicos Islands", dialCode: "+1649", circularFlag: "tc.svg" },
  { name: "Tuvalu", dialCode: "+688", circularFlag: "tv.svg" },
  { name: "Uganda", dialCode: "+256", circularFlag: "ug.svg" },
  { name: "Ukraine", dialCode: "+380", circularFlag: "ua.svg" },
  { name: "United Arab Emirates", dialCode: "+971", circularFlag: "ae.svg" },
  { name: "Uruguay", dialCode: "+598", circularFlag: "uy.svg" },
  { name: "Uzbekistan", dialCode: "+998", circularFlag: "uz.svg" },
  { name: "Vanuatu", dialCode: "+678", circularFlag: "vu.svg" },
  { name: "Venezuela", dialCode: "+58", circularFlag: "ve.svg" },
  { name: "Vietnam", dialCode: "+84", circularFlag: "vn.svg" },
  { name: "Wallis and Futuna Islands", dialCode: "+681", circularFlag: "wf.svg" },
  { name: "Yemen", dialCode: "+967", circularFlag: "ye.svg" },
  { name: "Zambia", dialCode: "+260", circularFlag: "zm.svg" },
  { name: "Zimbabwe", dialCode: "+263", circularFlag: "zw.svg" },
];
