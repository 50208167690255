export const vatCountries = [
  { name: "Germany", VATCode: "DE", circularFlag: "de.svg" },
  { name: "Portugal", VATCode: "PT", circularFlag: "pt.svg" },
  { name: "UK", VATCode: "GB", circularFlag: "gb.svg" },
  { name: "Austria", VATCode: "ATU", circularFlag: "at.svg" },
  { name: "Belgium", VATCode: "BE", circularFlag: "be.svg" },
  { name: "Bulgaria", VATCode: "BG", circularFlag: "bg.svg" },
  { name: "Croatia", VATCode: "HR", circularFlag: "hr.svg" },
  { name: "Cyprus", VATCode: "CY", circularFlag: "cy.svg" },
  { name: "Czech Republic", VATCode: "CZ", circularFlag: "cz.svg" },
  { name: "Denmark", VATCode: "DK", circularFlag: "dk.svg" },
  { name: "Estonia", VATCode: "EE", circularFlag: "ee.svg" },
  { name: "Finland", VATCode: "FI", circularFlag: "fi.svg" },
  { name: "France", VATCode: "FR", circularFlag: "fr.svg" },
  { name: "Greece", VATCode: "EL", circularFlag: "gr.svg" },
  { name: "Hungary", VATCode: "HU", circularFlag: "hu.svg" },
  { name: "Ireland", VATCode: "IE", circularFlag: "ie.svg" },
  { name: "Italy", VATCode: "IT", circularFlag: "it.svg" },
  { name: "Latvia", VATCode: "LV", circularFlag: "lv.svg" },
  { name: "Lithuania", VATCode: "LT", circularFlag: "lt.svg" },
  { name: "Luxembourg", VATCode: "LU", circularFlag: "lu.svg" },
  { name: "Malta", VATCode: "MT", circularFlag: "mt.svg" },
  { name: "Netherlands", VATCode: "NL", circularFlag: "nl.svg" },
  { name: "Poland", VATCode: "PL", circularFlag: "pl.svg" },
  { name: "Romania", VATCode: "RO", circularFlag: "ro.svg" },
  { name: "Spain", VATCode: "ES", circularFlag: "es.svg" },
  { name: "Slovakia", VATCode: "SK", circularFlag: "sk.svg" },
  { name: "Slovenia", VATCode: "SE", circularFlag: "si.svg" },
  { name: "Sweden", VATCode: "SE", circularFlag: "se.svg" },
];
