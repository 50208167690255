import React from "react";
import { useLocation } from "react-router-dom";

export default function MobileProgressBar() {
  const location = useLocation();

  const activeClass = "border-2 border-avocado-600 w-1/5 rounded-lg";
  const inactiveClass = "border-2 border-avocado-200 w-1/5 rounded-lg";

  const activeTextClass = "text-avocado-600 font-semibold w-1/5 text-sm";
  const inactiveTextClass = "text-avocado-200 w-1/5 text-sm";

  let progressBarData = [
    {
      name: "Membership",
      active: true,
    },
    {
      name: "Tell us about you",
      active: false,
    },
    {
      name: "Terms",
      active: false,
    },
    {
      name: "Payment",
      active: false,
    },
  ];

  switch (location.pathname) {
    case "/information":
      progressBarData[1].active = true;
      break;
    case "/terms":
      progressBarData[1].active = true;
      progressBarData[2].active = true;
  }

  return (
    <div className="mb-3 lg:hidden">
      <div
        className="flex flex-row justify-between mx-auto mb-2 "
        style={{ width: "90vw", maxWidth: "800px" }}
      >
        {progressBarData.map((item) => (
          <div className={item.active ? activeClass : inactiveClass}></div>
        ))}
      </div>
      <div
        className="hidden  flex-row justify-between mx-auto md:flex"
        style={{ width: "90vw", maxWidth: "800px" }}
      >
        {progressBarData.map((item) => (
          <div className={item.active ? activeTextClass : inactiveTextClass}>
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}
