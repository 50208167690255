import { useLocation } from "react-router-dom";

export default function ProgressBar() {
  const location = useLocation();

  const activeClass = "text-avocado-600 font-semibold border-r-2 border-avocado-600";
  const inactiveClass = "text-avocado-200 border-r-2 border-avocado-200";

  let progressBarData = [
    {
      name: "Membership",
      active: true,
    },
    {
      name: "Tell us about you",
      active: false,
    },
    {
      name: "Terms",
      active: false,
    },
    {
      name: "Payment",
      active: false,
    },
  ];

  switch (location.pathname) {
    case "/information":
      progressBarData[1].active = true;
      break;
    case "/terms":
      progressBarData[1].active = true;
      progressBarData[2].active = true;
  }

  return (
    <ul className="text-sm space-y-2" style={{ width: "140px" }}>
      {progressBarData.map((item) => (
        <li className={item.active ? activeClass : inactiveClass}>{item.name}</li>
      ))}
    </ul>
  );
}
