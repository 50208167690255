import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import MembershipPage from "./pages/MembershipPage";
import InformationPage from "./pages/InformationPage";
import TermsPage from "./pages/TermsPage";
import PaymentCacneled from "./pages/PaymentCanceled";
import PaymentSuccess from "./pages/PaymentSuccess";
import Header from "./components/Header";

import "./App.css";

export default function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<MembershipPage />} />
        <Route path="/information" element={<InformationPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/payment-failed" element={<PaymentCacneled />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />

        <Route path="/nofee">
          <Route index element={<MembershipPage />} />
          <Route path="information" element={<InformationPage />} />
          <Route path="terms" element={<TermsPage />} />
        </Route>
      </Routes>
    </Router>
  );
}
