export default function PaymentCacneled() {
  return (
    <>
      <div className="hidden lg:flex flex-grow items-center w-full">
        <div className="text-center w-full" style={{ marginTop: "-154px" }}>
          <h1 className="md:text-3xl font-semibold text-payment-dark-green text-center">
            Looks like you canceled
          </h1>
          <p className="text-center text-payment-light-green text-xl">
            Error? You can retry by clicking below
          </p>
          <div className="text-center mt-10">
            <button
              onClick={retry}
              className="text-white rounded-md text-xl w-32 py-2 px-6 bg-avocado-600"
            >
              Retry
            </button>
          </div>
        </div>
      </div>
      <div className="flex lg:hidden flex-grow items-center w-full">
        <div className="text-center w-full" style={{ marginTop: "-154px" }}>
          <h1 className="text-xl md:text-3xl font-semibold text-payment-dark-green text-center">
            Looks like you canceled
          </h1>
          <p className="text-center text-payment-light-green text-lg md:text-xl">
            Error? You can retry by clicking below
          </p>
        </div>
      </div>
      <div
        className="text-center h-20 lg:hidden"
        style={{ width: "90vw", marginLeft: "5vw", marginBottom: "5.5rem" }}
      >
        <button
          onClick={retry}
          className="text-white rounded-md text-lg md:text-xl w-full py-2 px-6 bg-avocado-600"
        >
          Retry
        </button>
      </div>
    </>
  );
}

function retry() {
  window.location.href = "/";
}
