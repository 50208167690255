import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

export default function PaymentSuccess() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    sendWelcomeEmail(searchParams.get("email"));
  }, []);

  return (
    <>
      <div className="hidden lg:flex flex-grow items-center w-full">
        <div className="text-center w-full" style={{ marginTop: "-170px" }}>
          <img
            src={require("../assets/full-logo.png")}
            className="mx-auto h-60 w-auto"
          ></img>
          <h1 className="md:text-3xl font-semibold text-payment-dark-green text-center">
            Welcome to NINHO
          </h1>
          <p className="text-center text-payment-light-green text-xl max-w-xl mx-auto">
            Thank you for becoming a member, we've sent you a welcome email that has
            everything you need to get started with your membership
          </p>
          <div className="text-center mt-10">
            <button
              onClick={() => window.location.replace("https://www.ninho.io")}
              className="text-white rounded-md text-xl w-32 py-2 px-6 bg-avocado-600"
            >
              Finish
            </button>
          </div>
        </div>
      </div>
      <div className="flex lg:hidden flex-grow items-center w-full">
        <div className="text-center w-full" style={{ marginTop: "-154px" }}>
          <img
            src={require("../assets/full-logo.png")}
            className="mx-auto h-44 md:h-60 w-auto"
          ></img>
          <h1 className="text-xl md:text-4xl font-semibold text-payment-dark-green text-center">
            Welcome to NINHO
          </h1>
          <p className="text-center text-payment-light-green text-lg md:text-xl px-20">
            Thank you for becoming a member, we've sent you a welcome email that has
            everything you need to get started with your membership
          </p>
        </div>
      </div>
      <div
        className="text-center h-20 lg:hidden"
        style={{ width: "90vw", marginLeft: "5vw", marginBottom: "5.5rem" }}
      >
        <button
          className="text-white rounded-md text-lg md:text-xl w-full py-2 px-6 bg-avocado-600"
          onClick={() => window.location.replace("https://www.ninho.io")}
        >
          Finish
        </button>
      </div>
    </>
  );
}

async function sendWelcomeEmail(email) {
  try {
    await axios.post("https://ninho-automation.vercel.app/send-welcome-email", {
      email: email,
    });
  } catch (error) {}
}
