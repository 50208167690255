import { useState, Fragment, useEffect } from "react";
import { RadioGroup, Dialog, Transition } from "@headlessui/react";
import ProgressBar from "../components/ProgressBar";
import MobileProgressBar from "../components/MobileProgressBar";
import { ReactComponent as HelpIcon } from "../assets/help-circle.svg";
import { XIcon } from "@heroicons/react/outline";

const plans = [
  {
    name: "Community",
    planDescription:
      "Community Membership provides access to our bar, restaurant, fitness studio, spa and Secret Garden as well as events and programming.",
  },
  {
    name: "Habitat",
    planDescription:
      "Our Community Membership plus a desk to call your own in one of our coworking spaces upstairs.",
  },
  {
    name: "Studio",
    planDescription:
      "Great for gathering a team together, our studios come in a range of sizes and are completely private spaces just for you",
  },
  {
    name: "Corporate",
    planDescription:
      "A staff perk, or a place for team members to work and host clients, this provides Community Membership for three or more team members",
  },
  {
    name: "Out of town",
    planDescription:
      "12 days of NINHO access a year for those flitting in and out of Lisbon",
  },
  {
    name: "Couples",
    planDescription:
      "Couples Membership provides access to our bar, restaurant, fitness studio, spa and Secret Garden as well as events and programming. Additionally, you can bring your partner",
  },
  {
    name: "Under 35's",
    planDescription:
      "Under 35s Membership provides access to our bar, restaurant, fitness studio, spa and Secret Garden as well as events and programming.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MembershipPage() {
  const [selected, setSelected] = useState(
    plans[plans.findIndex((plan) => plan.name === localStorage.getItem("membership"))]
  );
  const [mobileDescriptionOpen, setMobileDescriptionOpen] = useState(false);
  const [chosenPlan, setChosenPlan] = useState({ plan: "", description: "" });

  function openDescription(plan, description) {
    setChosenPlan({ plan: plan, description: description });
    setMobileDescriptionOpen(true);
  }

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [contentHeight, setContetHeight] = useState(
    document.documentElement.scrollHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <MobileProgressBar />
      <div
        style={{ width: "90vw", maxWidth: "800px", margin: "0 auto" }}
        className="flex flex-grow lg:flex-none"
      >
        <div className="hidden lg:w-1/4 lg:block">
          <ProgressBar />
        </div>

        <div className="w-full lg:w-3/4 space-y-4">
          <h2 className="text-lg md:text-xl text-avocado-700 font-semibold">
            Select your membership
          </h2>
          <RadioGroup value={selected} onChange={setSelected}>
            <div className="space-y-3">
              {plans.map((plan) => (
                <RadioGroup.Option
                  key={plan.name}
                  value={plan}
                  className={({ checked, active }) =>
                    classNames(
                      checked
                        ? "border-2 border-avocado-600"
                        : "border-2 border-avocado-100 hover:border-avocado-200",
                      "bg-avocado-100 text-avocado-700 rounded-lg shadow-sm px-4 py-3 cursor-pointer focus:outline-none"
                    )
                  }
                >
                  {({ checked, active }) => (
                    <div className="flex flex-row items-center justify-between w-100">
                      <RadioGroup.Label
                        as="div"
                        className="text-sm md:text-base text-avocado-700"
                      >
                        {plan.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description as="div">
                        <HelpIcon
                          className="text-beige hover-next-visible"
                          onClick={() => openDescription(plan.name, plan.planDescription)}
                        />
                        <div className="absolute not-shown">
                          <div className="absolute w-0 h-0 border-l-8 border-b-8 border-r-8 border-b-solid border-transparent border-b-white text-avocado-100 -left-1"></div>
                          <div className="absolute w-64 bg-white mt-3 shadow-xl p-4 -left-32 rounded-md text-sm">
                            {plan.planDescription}
                          </div>
                        </div>
                      </RadioGroup.Description>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>
      <div
        style={{ width: "90vw", maxWidth: "800px", marginBottom: "5.5rem" }}
        className={classNames("flex-none mx-auto mt-5 lg:block lg:static")}
      >
        <div className="flex flex-col md:flex-row gap-3 items-center justify-around lg:justify-end">
          <button
            className="text-avocado-600 w-full lg:w-20 rounded-md border border-avocado-600 p-2"
            onClick={toHome}
          >
            Cancel
          </button>
          <button
            className={classNames(
              selected ? "bg-avocado-600" : "bg-avocado-deactive",
              "text-white  w-full lg:w-20 rounded-md p-2"
            )}
            onClick={() => toInformation(selected)}
            disabled={selected ? false : true}
          >
            Next
          </button>
        </div>
      </div>
      <MobileMembershipDescription
        open={mobileDescriptionOpen}
        setOpen={setMobileDescriptionOpen}
        chosenPlan={chosenPlan}
      />
    </>
  );
}

function MobileMembershipDescription(props) {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="lg:hidden relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-light-gray bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:p-0 -translate-y-24">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => props.setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      About the {props.chosenPlan.plan} membership
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {props.chosenPlan.description}
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function toInformation(selected) {
  localStorage.setItem("membership", selected.name);

  window.location.href.includes("nofee")
    ? (window.location.href = "nofee/information")
    : (window.location.href = "/information");
}

function toHome() {
  // Clearing cache
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("email");
  localStorage.removeItem("phoneNumber");
  localStorage.removeItem("vat");
  localStorage.removeItem("address");
  localStorage.removeItem("city");
  localStorage.removeItem("country");
  localStorage.removeItem("postCode");
  localStorage.removeItem("membership");

  window.location.replace("https://www.ninho.io");
}
