import { useState } from "react";
import { MenuIcon } from "@heroicons/react/solid";
import styles from "../style.module.css";
import { useLocation } from "react-router-dom";

export default function Header() {
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const location = useLocation();

  const handleToggleBox = () => {
    setIsBoxVisible((prevState) => !prevState);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className={classNames(
          `${styles["navbar-no-shadow-container"]} ${styles["w-nav"]} z-50 bg-white`,
          location.pathname === "/nofee" ? null : "mb-5 lg:mb-20"
        )}
      >
        <div className={`${styles["container-regular"]} bg-white`}>
          <div className={`${styles["navbar-wrapper"]} bg-white`}>
            <div className={`${styles["div-block-93"]} lg:pt-4 bg-white`}>
              <a
                href="https://www.ninho.io/holding-page"
                className={styles["w-inline-block"]}
              >
                <img
                  src="https://uploads-ssl.webflow.com/6217c491fc763d9ca4ce166e/63d3c2dfd0dcb411fc6e77fb_Frame.svg"
                  loading="lazy"
                  alt=""
                  className={styles["image-16"]}
                />
              </a>
              <a
                href="https://www.ninho.io/holding-page"
                className={`${styles["navbar-brand"]} ${styles["w-nav-brand"]}`}
              >
                <img
                  src="https://uploads-ssl.webflow.com/6217c491fc763d9ca4ce166e/62442d46c341ba03c4523587_NINHO.png"
                  loading="lazy"
                  alt=""
                  className={styles["image-12"]}
                />
              </a>
            </div>
            <nav
              role="navigation"
              style={{ height: "25px" }}
              className={`${styles["nav-menu-wrapper"]} ${styles["w-nav-menu"]}`}
            >
              <ul
                role="list"
                className={`${styles["nav-menu-2"]} ${styles["w-list-unstyled"]}`}
              >
                <li className={`${styles["mobile-margin-top-10"]} hidden lg:block `}>
                  <div className={`${styles["nav-button-wrapper"]}`}>
                    <a
                      href="https://www.ninho.io/clubhouse"
                      className={styles["nav-link-2"]}
                    >
                      Clubhouse
                    </a>
                    <a
                      href="https://www.ninho.io/community"
                      className={styles["nav-link-2"]}
                    >
                      Community
                    </a>
                    <a
                      href="https://www.ninho.io/memberships"
                      className={styles["nav-link-2"]}
                    >
                      Memberships
                    </a>
                    <a href="https://www.ninho.io/faqs" className={styles["nav-link-2"]}>
                      FAQs
                    </a>
                    <a
                      href="https://www.ninho.io/reservations"
                      className={styles["nav-link-2"]}
                    >
                      Enquiries
                    </a>
                    <a
                      href="https://memberships.ninho.io/"
                      className={styles["nav-link-2"]}
                    >
                      Become a member
                    </a>
                    <a
                      href="https://www.instagram.com/weareninho/"
                      target="_blank"
                      className={`${styles["link-block-2"]} ${styles["w-inline-block"]}`}
                    >
                      <img
                        src="https://uploads-ssl.webflow.com/6217c491fc763d9ca4ce166e/62442e6e25b2867ec857d927_Group%209.png"
                        loading="lazy"
                        alt=""
                        className={`${styles["icon-sm"]} ${styles["ml-15px-xs"]} ${styles["mt-10px-xs"]}`}
                      />
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            <div className={`${styles["menu-button"]} ${styles["w-nav-button"]}`}>
              <MenuIcon
                className="h-5 w-5 text-black cursor-pointer"
                aria-hidden="true"
                onClick={handleToggleBox}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute bg-white w-full z-40 ${styles["box"]} lg:hidden`}
        style={{
          marginTop: "70px",
          height: "calc(100vh - 70px)",
          transform: `translateY(${isBoxVisible ? 0 : "-100vh"})`,
        }}
      >
        <ul
          role="list"
          className={`${styles["nav-menu-2"]} ${styles["w-list-unstyled"]} lg:hidden `}
          style={{ marginLeft: "16px" }}
        >
          <li>
            <div className={styles["nav-button-wrapper"]}>
              <a href="https://www.ninho.io/clubhouse" className={styles["nav-link-2"]}>
                Clubhouse
              </a>
              <a href="https://www.ninho.io/community" className={styles["nav-link-2"]}>
                Community
              </a>
              <a href="https://www.ninho.io/memberships" className={styles["nav-link-2"]}>
                Memberships
              </a>
              <a href="https://www.ninho.io/faqs" className={styles["nav-link-2"]}>
                FAQs
              </a>
              <a
                href="https://www.ninho.io/reservations"
                className={styles["nav-link-2"]}
              >
                Enquiries
              </a>
              <a href="https://memberships.ninho.io/" className={styles["nav-link-2"]}>
                Become a member
              </a>
              <a
                href="https://www.instagram.com/weareninho/"
                target="_blank"
                className={`${styles["link-block-2"]} ${styles["w-inline-block"]}`}
              >
                <img
                  src="https://uploads-ssl.webflow.com/6217c491fc763d9ca4ce166e/62442e6e25b2867ec857d927_Group%209.png"
                  loading="lazy"
                  alt=""
                  className={`${styles["icon-sm"]} ${styles["ml-15px-xs"]} ${styles["mt-10px-xs"]}`}
                />
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div
        className={classNames(
          "w-full bg-avocado-700 text-white mb-4 lg:mb-24 lg:mt-3",
          location.pathname === "/nofee" ? null : "hidden"
        )}
      >
        <h3 className="text-center p-2 text-base md:text-lg">
          This form does not charge joining fees - need to pay joining fees?{" "}
          <span className="underline cursor-pointer" onClick={goToNormalFlow}>
            Click here
          </span>
        </h3>
      </div>
    </>
  );
}

function goToNormalFlow() {
  window.location.href = "/";
}
